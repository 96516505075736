/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
				$(document).ready( function() {

						// page width
						$pageWidth = $(document).width();
						$(window).resize( function() {
							$pageWidth = $(document).width();
						});

						// top menu
						$('#header-nav > ul > li').hover( function() {
							$(this).addClass('hover');
							$(this).children('ul').slideDown(200);
						}, function() {
							$(this).removeClass('hover');
							$(this).children('ul').slideUp(150);
						});
						$('#header-nav ul ul').hover( function() {
							$(this).css('display','block');
						});

						// scroll dock
						$(window).scroll(function(){
							$reqScroll = 180;
							$dockHeight = $('#scrolldock').height();
							if ($reqScroll < $(window).scrollTop() && $('#scrolldock').hasClass('hidden')) {
								$('#scrolldock').removeClass('hidden')
									.css({'display':'block','top':'-'+$dockHeight+'px'})
									.animate({'top':0}, 200);
							} else if ($reqScroll > $(window).scrollTop() && !$('#scrolldock').hasClass('hidden')) {
								$('#scrolldock').addClass('hidden').animate({'top':'-'+$dockHeight+'px'}, 150, function() {
									$(this).css({'display':'none'});
								});
							}
						});

						// footer logo fades
						$('#footerbot .sponsors a').hover( function() {
							$(this).find('.img2').fadeIn(300);
						}, function() {
							$(this).find('.img2').fadeOut(200);
						});
						$('#footermid .spllogo').hover( function() {
							$(this).find('.img2').fadeIn(300);
						}, function() {
							$(this).find('.img2').fadeOut(200);
						});

						// video hovers
						$('a.lightboxme')
							.hover(function(){
								$(this).children().fadeTo(200, 1)
							},function(){
								$(this).children().fadeTo(200, 1)
							})
							.click( function() {
								// initiate overlay
								pageOverlayInVid($(this).attr('rel'),$(this).attr('title'));
							})
							.each( function() {
								$ytUrl = $(this).attr('href');
								$ytCode = $ytUrl.substr($ytUrl.length - 11);
								$(this).attr({'rel':$ytCode,'href':'javascript:void(0);'});
							});

						// image hovers
						$('a.fademe').hover(function(){
							$(this).children().fadeTo(200, 1)
						},function(){
							$(this).children().fadeTo(200, 1)
						});

					});

					// pageoverlay
					function pageOverlayIn() {
						if ($('#pageoverlay').length == 0) {
							$('body').append('<div id="pageoverlay">');
						}
						$htmlHeight = $(document).height();
						$('#pageoverlay')
						.height($htmlHeight+'px')
						.fadeTo(800, .9)
						.click( function() {
							vidViewOut();
							pageOverlayOut();
						});
					}


					// pageoverlay with video
					function pageOverlayInVid(ytCode,vidTitle) {
						if ($('#pageoverlay').length == 0) {
							$('body').append('<div id="pageoverlay">');
						}
						$htmlHeight = $(document).height();
						$('#pageoverlay')
						.height($htmlHeight+'px')
						.fadeTo(500, .9, function() {
							if ($('#vidview').length == 0) {
								$('body').append('<div id="vidview" class="video-overlay" style="display:none;"><div class="embed"><iframe width="900" height="506" src="https://www.youtube.com/embed/'+ytCode+'?rel=0&autohide=1&hd=1" frameborder="0" allowfullscreen></iframe></div></div>');
								$('#vidview')
									.append('<div class="call"><span class="tagline">Independent Confidential Support</span> <span class="number">Call (800) 506-0078</span> <span class="or">or</span> <a href="/chat">Chat Now</a></div>')
									.append(' ')
									.append('<div class="actions"><a class="more" href="/videos">Browse All Videos</a><a class="close" href="javascript:void(0);" title="Close"><span>Close</span></a></div>')
									.fadeIn(300)
									.find('.call')
										.css({'top':0})
										.delay(600)
										.animate({'top':'-60px'},500)
									.parent().find('.share')
										.css({'bottom':0})
										.delay(600)
										.animate({'bottom':'-45px'},500, function() {
											addthis.toolbox('#vidview', {}, {url:'https://www.nfllifeline.org/videos/'+vidTitle});
										})
									.parent().find('.actions')
										.css({'bottom':0})
										.delay(600)
										.animate({'bottom':'-46px'},500)
										.find('a')
											.click( function() {
												vidViewOut();
												pageOverlayOut();
											});
								$(document).keyup(function(e) {
									if (e.keyCode == 27) {
										vidViewOut();
										pageOverlayOut();
									}
								});
							}
						})
						.click( function() {
							vidViewOut();
							pageOverlayOut();
						});
					}

					function pageOverlayOut() {
						$('#pageoverlay').fadeOut(500);
					}

					function vidViewOut(ytCode) {
						if ($('#vidview').length > 0) {
							$('#vidview').fadeOut(200).remove();
						}
					}
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
				$(document).ready( function() {

					makeSlides();

				});

				function makeSlides() {
					// vars
					$sliderWidth = $pageWidth;
					$sliderParent = $('#groups');
					$slider = $sliderParent.find('.slider');
					$slides = $slider.find('.slides');
					$slideStart = 2; // slide to start at
					$totSlides = $slides.children('.slide').length;
					$nav = $slider.find('.nav ul');
					$slideSpeed = 900;
					$slideEase = 'easeInOutExpo';

					// arrange slides
					$slides.children('.slide')
						.css({'display':'none'});
					$slides.children('.slide:nth-child('+$slideStart+')')
						.addClass('active')
						.css({'display':'block'});
					$nav.find('li:nth-child('+$slideStart+')')
						.addClass('active');

					// prev/next
					$prevBtn = $slider.children('.slide-prev');
					$nextBtn = $slider.children('.slide-next');
					$prevBtn.click(function(){
						// stop slide automation
						clearInterval(autoGroups);
						prevSlide();
					}).hover(function(){
						$(this).addClass('slide-prev-hover');
					}, function(){
						$(this).removeClass('slide-prev-hover');
					});
					$nextBtn.click(function(){
						// stop slide automation
						clearInterval(autoGroups);
						nextSlide();
					}).hover(function(){
						$(this).addClass('slide-next-hover');
					}, function(){
						$(this).removeClass('slide-next-hover');
					});

					// left/right keyboard controls
					$(document).keydown(function(e){
						if (e.keyCode == 37) {
							//stop automation
							clearInterval(autoGroups);
							prevSlide();
							return false;
						} else if (e.keyCode == 39) {
							//stop automation
							clearInterval(autoGroups);
							nextSlide();
							return false;
						}
					});

					function prevSlide() {
						if (!$slider.hasClass('disabled')) {

							$slider.addClass('disabled');

							// check for changed browser size
							$sliderWidth = $pageWidth;

							// determine prev slide
							$currSlideNum = $slides.children('.active').index()+1;
							if ($currSlideNum == 1) {
								$prevSlideNum = $totSlides;
							} else {
								$prevSlideNum = $currSlideNum-1;
							}

							// change nav
							$nav.find('li:nth-child('+$currSlideNum+')').removeClass('active');
							$nav.find('li:nth-child('+$prevSlideNum+')').addClass('active');

							// animate away current slide
							$slides.children('.active').animate({'left':$sliderWidth+'px'}, $slideSpeed, $slideEase, function(){
								$(this).css({'display':'none','left':''}).removeClass('active');
							});

							// animate in prev slide
							$slides.children('.slide:nth-child('+$prevSlideNum+')')
								.css({'display':'block','left':'-'+$sliderWidth+'px'})
								.animate({'left':0}, $slideSpeed, $slideEase, function(){
									$(this).addClass('active');
									$slider.removeClass('disabled');
								}).children('.quote')
									.css({'left':'40%'})
									.animate({'left':'65%'}, $slideSpeed+300, $slideEase)
								.siblings('a')
									.css({'left':'40%'})
									.animate({'left':'65%'}, $slideSpeed+400, $slideEase);
						}
					}

					function nextSlide(){
						if (!$slider.hasClass('disabled')) {

							$slider.addClass('disabled');

							// check for changed browser size
							$sliderWidth = $pageWidth;

							// determine next slide
							$currSlideNum = $slides.children('.active').index()+1;
							if ($currSlideNum == $totSlides) {
								$nextSlideNum = 1;
							} else {
								$nextSlideNum = $currSlideNum+1;
							}

							// change nav
							$nav.find('li:nth-child('+$currSlideNum+')').removeClass('active');
							$nav.find('li:nth-child('+$nextSlideNum+')').addClass('active');

							// animate away current slide
							$slides.children('.active').animate({'left':'-'+$sliderWidth+'px'}, $slideSpeed, $slideEase, function(){
								$(this).css({'display':'none','left':''}).removeClass('active');
							});

							// animate in next slide
							$slides.children('.slide:nth-child('+$nextSlideNum+')')
								.css({'display':'block','left':$sliderWidth+'px'})
								.animate({'left':0}, $slideSpeed, $slideEase, function(){
									$(this).addClass('active');
									$slider.removeClass('disabled');
								}).children('.quote')
									.css({'left':'90%'})
									.animate({'left':'65%'}, $slideSpeed+300, $slideEase)
								.siblings('a')
									.css({'left':'90%'})
									.animate({'left':'65%'}, $slideSpeed+400, $slideEase);
						}
					}

					// nav clicks
					$nav.find('a').click(function(){
						if (!$(this).parent().hasClass('active') && !$slider.hasClass('disabled')) {

							// stop automation
							clearInterval(autoGroups);

							$slider.addClass('disabled');

							// check for changed browser size
							$sliderWidth = $pageWidth;

							// determine next slide and slide direction
							$currSlideNum = $slides.children('.active').index()+1;
							$nextSlideNum = $(this).parent().index()+1;

							if ($nextSlideNum > $currSlideNum) {
								// animate away current slide
								$slides.children('.active').animate({'left':'-'+$sliderWidth+'px'}, $slideSpeed, $slideEase, function(){
									$(this).css({'display':'none','left':''}).removeClass('active');
								});
								// animate in next slide
								$slides.children('.slide:nth-child('+$nextSlideNum+')')
									.css({'display':'block','left':$sliderWidth+'px'})
									.animate({'left':0}, $slideSpeed, $slideEase, function(){
										$(this).addClass('active');
										$slider.removeClass('disabled');
									}).children('.quote')
										.css({'left':'90%'})
										.animate({'left':'65%'}, $slideSpeed+300, $slideEase)
									.siblings('a')
										.css({'left':'90%'})
										.animate({'left':'65%'}, $slideSpeed+400, $slideEase);
							} else {
								// animate away current slide
								$slides.children('.active').animate({'left':$sliderWidth+'px'}, $slideSpeed, $slideEase, function(){
									$(this).css({'display':'none','left':''}).removeClass('active');
								});
								// animate in next slide
								$slides.children('.slide:nth-child('+$nextSlideNum+')')
									.css({'display':'block','left':'-'+$sliderWidth+'px'})
									.animate({'left':0}, $slideSpeed, $slideEase, function(){
										$(this).addClass('active');
										$slider.removeClass('disabled');
									}).children('.quote')
										.css({'left':'40%'})
										.animate({'left':'65%'}, $slideSpeed+300, $slideEase)
									.siblings('a')
										.css({'left':'40%'})
										.animate({'left':'65%'}, $slideSpeed+400, $slideEase);
							}

							// change nav
							$nav.find('li:nth-child('+$currSlideNum+')').removeClass('active');
							$nav.find('li:nth-child('+$nextSlideNum+')').addClass('active');

						}
					});

					// automate slides
					var i = 1;
					function automate() {
						nextSlide();
						i++;
					}
					var autoGroups = setInterval(automate, 8500);

				}
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
		'single_videos': {
			init: function() {
				var equalheight = function(container){

				var currentTallest = 0,
				     currentRowStart = 0,
				     rowDivs = new Array(),
				     $el,
						 currentDiv,
				     topPosition = 0;
				 $(container).each(function() {

				   $el = $(this);
				   $($el).height('auto')
				   topPosition = $el.position().top;

				   if (currentRowStart != topPosition) {
				     for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
				       rowDivs[currentDiv].height(currentTallest);
				     }
				     rowDivs.length = 0; // empty the array
				     currentRowStart = topPosition;
				     currentTallest = $el.height();
				     rowDivs.push($el);
				   } else {
				     rowDivs.push($el);
				     currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
				  }
				   for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
				     rowDivs[currentDiv].height(currentTallest);
				   }
				 });
				}

				$(window).on('load', function() {
				  equalheight('.video-card');
				});


				$(window).resize(function(){
				  equalheight('.video-card');
				});
      }
    },
		'videos': {
      init: function() {
				var equalheight = function(container){

				var currentTallest = 0,
				     currentRowStart = 0,
				     rowDivs = new Array(),
				     $el,
						 currentDiv,
				     topPosition = 0;
				 $(container).each(function() {

				   $el = $(this);
				   $($el).height('auto')
				   topPosition = $el.position().top;

				   if (currentRowStart != topPosition) {
				     for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
				       rowDivs[currentDiv].height(currentTallest);
				     }
				     rowDivs.length = 0; // empty the array
				     currentRowStart = topPosition;
				     currentTallest = $el.height();
				     rowDivs.push($el);
				   } else {
				     rowDivs.push($el);
				     currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
				  }
				   for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
				     rowDivs[currentDiv].height(currentTallest);
				   }
				 });
				}

				$(window).on('load', function() {
				  equalheight('.video-card');
				});


				$(window).resize(function(){
				  equalheight('.video-card');
				});
      }
    },
		'faq': {
      init: function() {
			  jQuery('a[href*="#"]:not([href="#"])').click(function() {
			    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
			      var target = jQuery(this.hash);
			      target = target.length ? target : jQuery('[name=' + this.hash.slice(1) +']');
			      if (target.length) {
			        jQuery('html, body').animate({
			          scrollTop: target.offset().top - 100
			        }, 400);
			        return false;
			      }
			    }
			  });
      }
    },
		'page_template_template_resources': {
			init: function() {
				jQuery(function ($) {

					// Override hash jump on load
					if (location.hash) {
					  setTimeout(function() {

					    window.scrollTo(0, 0);
					  }, 1);
					}

					var $container = $('#resources'),
							filters = {},
							$filterDisplay = $('#filter-display');

					// Used to set filters with query string
					function getHashFilter() {
	          var hash = location.hash.match( /[^#]*$/i );
	          return decodeURIComponent( hash );
	        }

					if( getHashFilter() != "" ) {

						var hashFilter = getHashFilter(),
								$filterEl = $('#' + hashFilter),
								hashFilterClean = hashFilter.replace(/-/gi, ' ');

						$container.isotope({
              itemSelector : '.resource-item',
              layoutMode: 'fitRows',
              filter: '.' + hashFilter
            });

						// $filterEl.attr('checked','checked');
						// $filterEl.parent('li').siblings('li').children( 'input.all' ).removeAttr('checked');
						$filterEl.trigger( "click" );
						$filterDisplay.html('<p class="filter-display__current">Current Filters</p><button class="filter-link" value="' + hashFilter +'">' + hashFilterClean + '</button>');

						handleButtons();

					} else {

						$container.isotope({
							itemSelector : '.resource-item',
							layoutMode : 'fitRows'
						});

					}

					$('#resource-filters').on( 'change', function( jQEvent ) {
						var $checkbox = $( jQEvent.target );

						manageCheckbox( $checkbox );

						var comboFilter = getComboFilter( filters );
						var activeFilters = displayChosenFilters( filters );

						$container.isotope({ filter: comboFilter });

						$filterDisplay.html('<p class="filter-display__current">Current Filters</p>');
						$filterDisplay.append( activeFilters );

						handleButtons();

						if( $('.filter-link').length ){

						} else {
							$('#filter-display').html('');
						}


					});

					function displayChosenFilters( filters ){
						var i = 0;
						var filterList = [];
						var filterListSplit = [];
						var temp;
						var filterLinks = '';

						for ( var prop in filters ) {
							filterList.push( filters[ prop ].join() );
							i++;
					 	}

						filterList.forEach(function( el ) {
							elSlug = el.split('.').join('');
							elClean = el.split('.').join('').split('-').join(' ');
							temp = elClean.split(',');
							// console.log('templist:' + temp);
							for( i = 0; i < temp.length; i++ ){
								var tempSlug = temp[i].split(' ').join('-');
								filterListSplit.push(temp[i]);
								if (tempSlug != ''){
									filterLinks = filterLinks + '<button type="button" class="filter-link" value="' + tempSlug + '">' + temp[i] + '</button>';
								}
							}
						});

						return filterLinks;

					}

					function handleButtons(){
						$('.filter-link').on('click', function(){
							var clickedButton = $(this).val();
							$('#' + clickedButton).removeAttr('checked');
							$(this).remove();
							manageCheckbox( $('#' + clickedButton) );
							var comboFilter = getComboFilter( filters );
							$container.isotope({ filter: comboFilter });
							if( $('.filter-link').length === 0){
								$('#filter-display').html('');
							}
						});
					}

					function getComboFilter( filters ) {
					  var i = 0;
					  var comboFilters = [];
					  var message = [];

					  for ( var prop in filters ) {
					    // message.push( filters[ prop ].join(' ') );
							// console.log( 'message:' + message );
					    var filterGroup = filters[ prop ];
					    // skip to next filter group if it doesn't have any values
					    if ( !filterGroup.length ) {
					      continue;
					    }
					    if ( i === 0 ) {
					      // copy to new array
					      comboFilters = filterGroup.slice(0);
					    } else {
					      var filterSelectors = [];
					      // copy to fresh array
					      var groupCombo = comboFilters.slice(0); // [ A, B ]
					      // merge filter Groups
					      for (var k=0, len3 = filterGroup.length; k < len3; k++) {
					        for (var j=0, len2 = groupCombo.length; j < len2; j++) {
					          filterSelectors.push( groupCombo[j] + filterGroup[k] ); // [ 1, 2 ]
					        }

					      }

					      // apply filter selectors to combo filters for next group
					      comboFilters = filterSelectors;
					    }
					    i++;
					  }

						var comboFilter = comboFilters.join(', ');
					  return comboFilter;
					}

					function manageCheckbox( $checkbox ) {
					  var checkbox = $checkbox[0];
						// console.log(checkbox);

					  var group = $checkbox.parents('.option-set').attr('data-group');

					  // create array for filter group, if not there yet
					  var filterGroup = filters[ group ];
					  if ( !filterGroup ) {
					    filterGroup = filters[ group ] = [];
					  }

						// set isAll to the all box
					  var isAll = $checkbox.hasClass('all');

					  // reset filter group if the all box was checked
					  if ( isAll ) {
					    delete filters[ group ];
					    if ( !checkbox.checked ) {
					      checkbox.checked = 'checked';
					    }
					  }
					  // index of
					  var index = $.inArray( checkbox.value, filterGroup );

					  if ( checkbox.checked ) {
					    var selector = isAll ? 'input' : 'input.all';
					    $checkbox.parent('li').siblings('li').children( selector ).removeAttr('checked');


					    if ( !isAll && index === -1 ) {

								// reset filter group
								filters[ group ] = [];
								// add all checked values to filter group
								$checkbox.parent('li').siblings('li').children('input').each( function(){
									if ( $(this).is(':checked') ){
										filters[ group ].push( $(this).val() );
									}
								});
					      // add newly checked filter to group
					      filters[ group ].push( checkbox.value );
					    }

							if ( !isAll && $checkbox.parent('li').parent('ul').data('group') === 'nfl_resources' ){

								$checkbox.parent('li').siblings('li').children('input').removeAttr('checked');
								filters[ group ] = [];
								filters[ group ].push( checkbox.value );

							}

					  } else if ( !isAll ) {
					    // remove filter from group
					    filters[ group ].splice( index, 1 );
					    // if unchecked the last box, check the all
					    // if ( !$checkbox.parent('li').siblings('li').children('input[checked]').length ) {
							if( filterGroup.length === 0 ){
								// console.log('none checked');
					      $checkbox.parent('li').siblings('li').children('input.all').attr('checked', 'checked');
					    }
					  }
					}

				});
			}
		},
     'page_template_template_toolkit': {
      init: function() {
        // JavaScript to be fired on the about us page

				jQuery(function ($) {
				
					//filter toggle
					$( '.toggleButton' ).on( 'click', function() {
						$( '.portfolio-filters__wrapper' ).toggleClass( 'portfolio-filters__wrapper--show' );
					  } )

				 var $container = $('#portfolio'); //The ID for the list with all the blog posts
				 $container.isotope({ //Isotope options, 'item' matches the class in the PHP
				 itemSelector : '.portfolio-item',
				   layoutMode : 'fitRows'
				 });

				 //Add the class selected to the item that is clicked, and remove from the others
				 var $checked = $('input[type="checkbox"]:checked');
				 
				 $('input[type="checkbox"]').click(function(){
				 var $this = $(this);
				  if($(this).prop("checked") == true){
					 $('input[type="checkbox"]').attr("checked", false); //uncheck all checkboxes
                     $(this).attr("checked", true);
                     var selector = $(this).attr('data-type');
				     $container.isotope({ filter: selector });
				}
				
				 });

				});
      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  $( '#navMoreBtn' ).on( 'click', function() {
  	$( this ).closest( '.navbar-collapse' ).toggleClass( 'navbar-open' );
  } );

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
